import React from 'react';
import { Typography, Stack, Button } from '@mui/material';
import IconAll from '../assets/icons/all.png';
import IconBack from '../assets/icons/back.png';
import IconCardio from '../assets/icons/cardio.png';
import IconChest from '../assets/icons/chest.png';
import IconLowerArms from '../assets/icons/lower-arms.png';
import IconLowerLegs from '../assets/icons/lower-legs.png';
import IconNeck from '../assets/icons/neck.png';
import IconShoulders from '../assets/icons/shoulders.png';
import IconUpperArms from '../assets/icons/upper-arms.png';
import IconUpperLegs from '../assets/icons/upper-legs.png';
import IconWaist from '../assets/icons/waist.png';


import BodyPartImage from '../assets/icons/body-part.png';
import TargetImage from '../assets/icons/target.png';
import EquipmentImage from '../assets/icons/equipment.png';

// Create an object to map item names to images
const imageMap = {
    all: IconAll,
    back: IconBack,
    cardio: IconCardio,
    chest: IconChest,
    'lower arms': IconLowerArms,
    'lower legs': IconLowerLegs,
    neck: IconNeck,
    shoulders: IconShoulders,
    'upper arms': IconUpperArms,
    'upper legs': IconUpperLegs,
    waist: IconWaist,
    // Add more mappings for each item and its corresponding image
};



const Detail = ({ exerciseDetail }) => {

    

    const { bodyPart, gifUrl, name, target, equipment } = exerciseDetail;
    const Icon = imageMap[bodyPart?.toLowerCase()];

  const extraDetail = [
    {
      icon: Icon,
      name: bodyPart,
    },
    //{
    //  icon: TargetImage,
    //  name: target,
    //},
    //{
    //  icon: EquipmentImage,
    //  name: equipment,
    //},
  ];

  return (
    <Stack gap="60px" sx={{ flexDirection: { lg: 'row' }, p: '20px', alignItems: 'center' }}>
      <img src={gifUrl} alt={name} loading="lazy" className="detail-image" />
      <Stack sx={{ gap: { lg: '35px', xs: '20px' } }}>
              <Typography textTransform="uppercase" sx={{ fontSize: { lg: '36px', xs: '24px' } }} fontWeight="thin">
          {name}
        </Typography>
        <Typography sx={{ fontSize: { lg: '24px', xs: '18px' } }} color="#4F4C4C">
          Exercises keep you strong.{' '}
          <span style={{ textTransform: 'uppercase' }}>{name}</span> bup is one
          of the best <br /> exercises to target your {target}. It will help you improve your{' '}
          <br /> mood and gain energy.
        </Typography>
        {extraDetail?.map((item) => (
          <Stack key={item.name} direction="row" gap="24px" alignItems="center">
                <Button sx={{ background: '#ffffff', borderRadius: '50%', width: '100px', height: '100px', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)', }}>
              <img src={item.icon} alt={bodyPart} style={{ width: '50px', height: '50px' }} />
            </Button>
                <Typography textTransform="uppercase" sx={{
                    fontSize: { lg: '30px', xs: '20px' },
                    fontWeight: 'thin', }}>
              {item.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default Detail