import React, { useState } from 'react';
import { Box } from '@mui/material';
import Exercises from '../components/Exercises';
import SearchExercises from '../components/SearchExercises';
import HeroBanner from '../components/HeroBanner';
import ErrorBoundary from '../components/ErrorBoundary';

// import MultiSelectList from '../components/MultiSelectList';


const Home = () => {
  const [bodyPart, setBodyPart] = useState('all')
  const [exercises, setExercises] = useState([]);

//console.log(bodyPart)

/*item select list reorder*/
        // const initialItems = [
        //     { id: 'item1', text: 'Item 1' },
        //     { id: 'item2', text: 'Item 2' },
        //     { id: 'item3', text: 'Item 3' },
            
        // ];

        // const [selectedItems, setSelectedItems] = useState([]);
        // const [items, setItems] = useState(initialItems);

        // const handleToggle = (itemId) => {
        //     setSelectedItems((prevSelectedItems) =>
        //         prevSelectedItems.includes(itemId)
        //             ? prevSelectedItems.filter((id) => id !== itemId)
        //             : [...prevSelectedItems, itemId]
        //     );
        // };

        // const handleDragEnd = (result) => {
        //     if (!result.destination) return;

        //     const reorderedItems = [...items];
        //     const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        //     reorderedItems.splice(result.destination.index, 0, reorderedItem);

        //     setItems(reorderedItems);
        // };







  return (
    <Box>
        <HeroBanner />
        <SearchExercises 
        setExercises={setExercises}
        bodyPart={bodyPart} 
        setBodyPart={setBodyPart}
        />
        <ErrorBoundary>
        <Exercises 
        exercises={exercises}
        setExercises={setExercises}
        bodyPart={bodyPart} 
          />
</ErrorBoundary>
          {/* <div>
              <h1>Multi-Select and Reorder List</h1>
              <MultiSelectList
                  items={items}
                  selectedItems={selectedItems}
                  onToggle={handleToggle}
                  onDragEnd={handleDragEnd}
              />
          </div> */}
    </Box>
  )
}

export default Home