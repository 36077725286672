import React from 'react'
import { Stack, Typography } from '@mui/material';


import IconAll from '../assets/icons/all.png';
import IconBack from '../assets/icons/back.png';
import IconCardio from '../assets/icons/cardio.png';
import IconChest from '../assets/icons/chest.png';
import IconLowerArms from '../assets/icons/lower-arms.png';
import IconLowerLegs from '../assets/icons/lower-legs.png';
import IconNeck from '../assets/icons/neck.png';
import IconShoulders from '../assets/icons/shoulders.png';
import IconUpperArms from '../assets/icons/upper-arms.png';
import IconUpperLegs from '../assets/icons/upper-legs.png';
import IconWaist from '../assets/icons/waist.png';
 
// Create an object to map item names to images
const imageMap = {
    all: IconAll,
    back: IconBack,
    cardio: IconCardio,
    chest: IconChest,
    'lower arms' : IconLowerArms,
    'lower legs': IconLowerLegs,
    neck: IconNeck,
    shoulders: IconShoulders,
    'upper arms': IconUpperArms,
    'upper legs': IconUpperLegs,
    waist: IconWaist,
    // Add more mappings for each item and its corresponding image
};


const BodyPart = ({ item, setBodyPart, bodyPart }) => {


    const Icon = imageMap[item.toLowerCase()];
   
  return (
    <Stack
    type="button"
    alignItems="center"
    justifyContent="center"
    className="bodyPart-card"
    sx={{
      borderTop: bodyPart === item ? '4px solid #333333' : '',
                pt:'10px',
    
      width: '80px',
      height: 'auto',
      cursor: 'pointer',
      gap: '10px',
    }}
    onClick= {() => {
      setBodyPart(item);
      window.scrollTo({top: 1800, left: 100, behavior: 'smooth'})
    }}
      >
          <img src={Icon} alt={item} style={{ width: '70px', height: '70px'}} />
      <Typography fontSize="14px" fontWeight="bold" color="#333333" textTransform="capitalize">{item}</Typography>
    </Stack>
  )
} 

export default BodyPart