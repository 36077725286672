import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { exerciseOptions, fetchData } from '../utils/fetchData';
import HorizontalScrollbar from './HorizontalScrollbar';

import BodyPartSelection from './BodyPartSelection';
//import _ from 'lodash'; //npm install lodash

const SearchExercises = ({ setExercises, bodyPart, setBodyPart }) => {
  const [search, setSearch] = useState('');
  const [bodyParts, setBodyParts] = useState([]);

  useEffect(() => {
    const fetchExercisesData = async () => {
      const bodyPartsData = await fetchData('https://exercisedb.p.rapidapi.com/exercises/bodyPartList', exerciseOptions);

      setBodyParts(['all', ...bodyPartsData]);
    };

    fetchExercisesData();
  }, []);

  const handleSearch = async () => {
    if (search) {
        const exercisesData = await fetchData('https://exercisedb.p.rapidapi.com/exercises', exerciseOptions);
        console.log(exercisesData);
        const searchedExercises = exercisesData.filter(
            (item) => item.name.toLowerCase().includes(search)
                   || item.target.toLowerCase().includes(search)
                   || item.equipment.toLowerCase().includes(search)
                   || item.bodyPart.toLowerCase().includes(search),
        );

        window.scrollTo({ top: 1800, left: 100, behavior: 'smooth' });

        setSearch('');
        setExercises(searchedExercises);
        console.log(searchedExercises);
    }
};

  return (
    <Stack
          alignItems="left" mt="37px" justifyContent="left"
          sx={{
              margin: { lg: '20px 80px', sm: '20px 30px', xs: '20px 10px' }

              
          }}>
        <Typography fontWeight="700"
        sx={{
            fontSize: { lg: '40px', xs: '30px' },
            padding: { lg: '10px 20px', sm: '10px 10px' },
        }}
        mb="50px"
              textAlign="left"
              textTransform="uppercase"
        >
            What should we do?
        </Typography>
          <Box position="relative" mb="72px" sx={{ padding: { lg: '0px 20px', sm: '0px 10px' }, }}>
            <TextField 
            sx={{
            input: { 
                fontWeight: '700',
                border: 'none', 
                    borderRadius: '4px',
                    
            },
            width: { lg:'320px', xs: '250px'},
            backgroundColor: '#fff',
                      borderRadius: '4px',
                      
            }}
                height="76px"
                value={search}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                placeholder="Search Exercises"
                type="text"
            />
            <Button className="search-btn"
            sx={{
                bgcolor: '#000000',
                color: '#fff',
                textTransform: 'uppercase',
                width: { lg: '140px', xs: '80px'},
                fontSize: { lg: '20px', xs: '14px'},
                height: '56px',
                position: 'absolute',
                left: { lg: '310px', xs: '240px' },
            }} 
            onClick={handleSearch}
            >
                Search
            </Button>
        </Box>
        <Box
        sx={{
                  position: 'relative', width: '100%', p: '20px 0px', 
              }}>


            <BodyPartSelection 
            data={bodyParts}
            bodyParts 
            bodyPart={bodyPart}
            setBodyPart={setBodyPart}
            isBodyParts
            />
        </Box>

    </Stack>
  )
}

export default SearchExercises 

/* 
import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { exerciseOptions, fetchData } from '../utils/fetchData';
import HorizontalScrollbar from './HorizontalScrollbar';

import BodyPartSelection from './BodyPartSelection';

const SearchExercises = ({setExercises, bodyPart, setBodyPart}) => {
    const [search, setsearch] = useState('');
    
    const [bodyParts, setBodyParts] = useState([])

    useEffect(() => {
        const fetchExercisesData = async () => {
          try {
            const bodyPartsData = await fetchData('https://exercisedb.p.rapidapi.com/exercises/bodyPartList', exerciseOptions);
            if (Array.isArray(bodyPartsData)) {
              setBodyParts(['all', ...bodyPartsData]);
            } else {
              console.error('bodyPartsData is not an array');
            }
      
            const exercisesData = await fetchData('https://exercisedb.p.rapidapi.com/exercises', exerciseOptions);
            if (Array.isArray(exercisesData)) {
              setExercises(exercisesData);
            } else {
              console.error('exercisesData is not an array');
            }
          } catch (error) {
            console.error('Error fetching exercises data:', error);
          }
        };
      
        fetchExercisesData();
      }, [setExercises]);
    

    const handleSearch = async () => {
        if (search) {
            try {
                const exercisesData = await fetchData('https://exercisedb.p.rapidapi.com/exercises', exerciseOptions);
    
                const searchedExercises = exercisesData.filter(
                    (exercise) =>
                        exercise.name.toLowerCase().includes(search) ||
                        exercise.target.toLowerCase().includes(search) ||
                        exercise.equipment.toLowerCase().includes(search) ||
                        exercise.bodyPart.toLowerCase().includes(search)
                );
    
                setsearch('');
                setExercises(searchedExercises);
            } catch (error) {
                console.error('Error fetching exercises data:', error);
            }
        }
    };

  return (
    <Stack
          alignItems="left" mt="37px" justifyContent="left"
          sx={{
              margin: { lg: '20px 80px', sm: '20px 30px', xs: '20px 10px' }

              
          }}>
        <Typography fontWeight="700"
        sx={{
            fontSize: { lg: '40px', xs: '30px' },
            padding: { lg: '10px 20px', sm: '10px 10px' },
        }}
        mb="50px"
              textAlign="left"
              textTransform="uppercase"
        >
            What should we do?
        </Typography>
          <Box position="relative" mb="72px" sx={{ padding: { lg: '0px 20px', sm: '0px 10px' }, }}>
            <TextField 
            sx={{
            input: { 
                fontWeight: '700',
                border: 'none', 
                    borderRadius: '4px',
                    
            },
            width: { lg:'320px', xs: '250px'},
            backgroundColor: '#fff',
                      borderRadius: '4px',
                      
            }}
                height="76px"
                value={search}
                onChange={(e) => setsearch(e.target.value.toLowerCase())}
                placeholder="Search Exercises"
                type="text"
            />
            <Button className="search-btn"
            sx={{
                bgcolor: '#000000',
                color: '#fff',
                textTransform: 'uppercase',
                width: { lg: '140px', xs: '80px'},
                fontSize: { lg: '20px', xs: '14px'},
                height: '56px',
                position: 'absolute',
                left: { lg: '310px', xs: '240px' },
            }} 
            onClick={handleSearch}
            >
                Search
            </Button>
        </Box>
        <Box
        sx={{
                  position: 'relative', width: '100%', p: '20px 0px', 
              }}>
              <HorizontalScrollbar  data={bodyParts}
                  bodyPart={bodyPart}
                  setBodyPart={setBodyPart}
                  isBodyParts

            />

            <BodyPartSelection 
            data={bodyParts}
            bodyPart={bodyPart}
            setBodyPart={setBodyPart}
            isBodyParts
            />
        </Box>

    </Stack>
  )
}

export default SearchExercises
 */