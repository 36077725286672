export const exerciseOptions = {
  method: 'GET',
  headers: {
    //'x-rapidapi-key': 'a8d589a5femsh5b8d0a1b222de66p16f367jsn3372b7166d04',
    'x-rapidapi-key': '926731e800mshc97fca9d23149a1p174087jsn2dc2138a0089', //cyrilrobertmma
    'x-rapidapi-host': 'exercisedb.p.rapidapi.com'
  }
};

/* export const youtubeOptions = {
        method: 'GET',

        headers: {
          'X-RapidAPI-Key': 'a8d589a5femsh5b8d0a1b222de66p16f367jsn3372b7166d04',
          //'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
          'X-RapidAPI-Host': 'youtube-search-and-download.p.rapidapi.com'
        }
      }; */

      export const fetchData = async (url, options) => {
        const headers = {
          ...options.headers,
          'x-rapidapi-key': '926731e800mshc97fca9d23149a1p174087jsn2dc2138a0089'
        };
      
        const response = await fetch(url, { ...options, headers });
        const data = await response.json();
      
        return data;
      };
/* export const fetchData = async (url, options) => {
    const response = await fetch(url, options);
    const data = await response.json();

    return data;
} */
