import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';

import HorizontalScrollbar from './HorizontalScrollbar';
import BodyPartSelection from './BodyPartSelection';
import { exerciseOptions, fetchData } from '../utils/fetchData';
import Loader from './Loader';

const SimilarExercises = ({ targetMuscleExercises, equipmentExercises }) => {




  return (
    <Box sx={{ mt: { lg: '100px', xs: '0px' } }}>
      <Typography textTransform="uppercase" sx={{ fontSize: { lg: '36px', xs: '24px' }, ml: '20px' }} mb="0px">
        Similar <span style={{ color: '#ba0000 ', }}>Target Muscle</span> exercises
      </Typography>
      <Stack direction="row" sx={{ p: 1, position: 'relative',}}>
        {targetMuscleExercises.length !== 0 ? <HorizontalScrollbar data={targetMuscleExercises} /> : <Loader />}
      </Stack>
      <Typography textTransform="uppercase" sx={{ fontSize: { lg: '36px', xs: '24px' }, ml: '20px', mt: { lg: '100px', xs: '60px' } }} mb="0px">
        Similar <span style={{ color: '#ba0000 ', }}>Equipment</span> exercises
      </Typography>
      <Stack direction="row" sx={{ p: 1, position: 'relative' }}>
        {equipmentExercises.length !== 0 ? 
        <BodyPartSelection data={equipmentExercises} /> 
        
        :
         <Loader />}
      </Stack>
    </Box>
  );
};

export default SimilarExercises; 


/* import React from 'react';
import { Typography, Box, Stack } from '@mui/material';

import HorizontalScrollbar from './HorizontalScrollbar';
import Loader from './Loader';

const SimilarExercises = ({ targetMuscleExercises, equipmentExercises }) => (
  <Box sx={{ mt: { lg: '100px', xs: '0px' } }}>
        <Typography textTransform="uppercase" sx={{ fontSize: { lg: '36px', xs: '24px' }, ml: '20px' }} mb="0px">
            Similar <span style={{ color: '#ba0000 ', }}>Target Muscle</span> exercises
    </Typography>
        <Stack direction="row" sx={{ p: 1, position: 'relative',}}>
            {targetMuscleExercises.length !== 0 ? <HorizontalScrollbar data={targetMuscleExercises}
                /> : <Loader />}
    </Stack>
        <Typography textTransform="uppercase" sx={{ fontSize: { lg: '36px', xs: '24px' }, ml: '20px', mt: { lg: '100px', xs: '60px' } }} mb="0px">
            Similar <span style={{ color: '#ba0000 ', }}>Equipment</span> exercises
    </Typography>
        <Stack direction="row" sx={{ p: 1, position: 'relative' }}>
      {equipmentExercises.length !== 0 ? <HorizontalScrollbar data={equipmentExercises} /> : <Loader />}
    </Stack>
  </Box>
);

export default SimilarExercises; */
