import React from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

//import Logo from '../assets/images/Logo.png';
import LogoText from '../assets/images/le-dojo-logo-black.png';

const Navbar = () => {
  return (
    <Stack
        direction="row"
        justifyContent="space-around"
        sx={{
            gap:{ sm:'40px', xs:'30px'}, mt:{sm:'20px', xs:'15px'},
            justifyContent: 'none'
        }}
        px="20px"
    >
        <Link to="/">
              <img className="nav_logo" src={LogoText} alt="Le Dojo Logo" style={{
                

            }} />
        </Link>
        <Stack
            direction="row"
            gap="30px"
            fontSize="20px"
              alignItems='center'
            >
              <Link to="/" style={{
                  textTransform: 'uppercase',
                textDecoration: 'none',
                color: '#333333',
                borderBottom: '3px solid #333333'
            }}>Home</Link>
            <a href="#exercises" style={{
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  color: '#333333',
                 
            }}>Exercises</a>
        </Stack>
    </Stack>
  )
}

export default Navbar