import React from 'react'
import {Link} from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { Height } from '@mui/icons-material';

const ExerciseCard = ({ exercise }) => {
  return (
      <Link className="exercise-card" to={`/exercise/${exercise.id}`} > 
          <Stack direction="row">
              <Button sx={{
                  ml: '15px', mt: '10px', color: '#fff', background: '#333333', fontSize: '14px', borderRadius: '20px', textTransform: 'capitalize',
                  '&:hover': {
                      backgroundColor: '#000000 ', // Change background color on hover

                  }, }}>
                  {exercise.bodyPart}
              </Button>
              <Button sx={{
                  ml: '15px', mt: '10px', color: '#fff', background: '#cccccc', fontSize: '14px', borderRadius: '20px', textTransform: 'capitalize',
                  '&:hover': {
                      backgroundColor: '#000000 ', // Change background color on hover

                  },
              }}>
                  {exercise.target}
              </Button>
          </Stack>
        <img src={exercise.gifUrl} alt={exercise.name} Loading="lazy" />
        
        <Typography ml="15px" color="#333333" fontWeight="bold" mt="10px"  textTransform="capitalize" fontSize="16px">
            {exercise.name}
        </Typography>
    </Link>
  )
}

export default ExerciseCard

/* 
import React from 'react'
import {Link} from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

const ExerciseCard = ({ exercise }) => {
  return (
      <Link className="exercise-card" to={`/exercise/${exercise.id}`} > 
          <Stack direction="row">
              <Button sx={{
                  ml: '15px', mt: '10px', color: '#fff', background: '#333333', fontSize: '14px', borderRadius: '20px', textTransform: 'capitalize',
                  '&:hover': {
                      backgroundColor: '#000000 ', // Change background color on hover

                  }, }}>
                  {exercise.bodyPart}
              </Button>
              <Button sx={{
                  ml: '15px', mt: '10px', color: '#fff', background: '#cccccc', fontSize: '14px', borderRadius: '20px', textTransform: 'capitalize',
                  '&:hover': {
                      backgroundColor: '#000000 ', // Change background color on hover

                  },
              }}>
                  {exercise.target}
              </Button>
          </Stack>
        <img src={exercise.gifUrl} alt={exercise.name} Loading="lazy" />
        
        <Typography ml="21px" color="#333333" fontWeight="bold" mt="11px" pb="10px" textTransform="capitalize" fontSize="20px">
            {exercise.name}
        </Typography>
    </Link>
  )
}

export default ExerciseCard
*/