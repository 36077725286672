import React, { useContext } from 'react'
import { Box, Stack, Typography } from '@mui/material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ExerciseCard from './ExerciseCard';

import BodyPart from './BodyPart';


const BodyPartSelection = ({ data, bodyPart, setBodyPart, isBodyParts }) => {
  return (
    <Stack
    
    alignItems="left"
    
    justifyContent="left"
    sx={{
      width:'100%',
      margin: { lg: '15px auto', md: '10px auto', sm: '00px auto', xs: '0px auto' },
      display: 'flex',        // Set display to 'flex'
      flexDirection: 'row',    // Make items appear side by side
      flexWrap: 'wrap',
    }}
  >
    {data.map((item) => (
      <Box
        key={item.id || item}
        itemId={item.id || item}
        title={item.id || item}
        m="0 auto"
        sx={{
          flexBasis: { xs: 'auto', sm: 'auto' }, // Set flex-basis to 100% for small screens and auto for larger screens
          maxWidth: { xs: 'auto', sm: 'auto' }, // Set max-width to 100% for small screens and 300px for larger screens
        }}
      >
        {isBodyParts ? (
          <BodyPart item={item} bodyPart={bodyPart} setBodyPart={setBodyPart} />
        ) : (
          <ExerciseCard exercise={item} />
        )}
      </Box>
    ))}
  </Stack>
  )
} 
export default BodyPartSelection

/* 
import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ExerciseCard from './ExerciseCard';

import BodyPart from './BodyPart';
import RightArrowIcon from '../assets/icons/right-arrow.png';
import LeftArrowIcon from '../assets/icons/left-arrow.png';


const LeftArrow = () => {
    const { scrollPrev } = useContext(VisibilityContext);
  
    return (
      <Typography onClick={() => scrollPrev()} className="right-arrow">
        <img src={LeftArrowIcon} alt="right-arrow" />
      </Typography>
    );
  };
  
  const RightArrow = () => {
    const { scrollNext } = useContext(VisibilityContext);
  
    return (
      <Typography onClick={() => scrollNext()} className="left-arrow">
        <img src={RightArrowIcon} alt="left-arrow" />
      </Typography>
    );
  };

const HorizontalScrollbar = ({ data, bodyPart, setBodyPart, isBodyParts }) => {
  return (
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {data.map((item) => (
    <Box
        key={item.id || item}
        itemId={item.id || item}
        title={item.id || item}
        m="0 5px"
        >
            {isBodyParts ? <BodyPart
            item={item} 
            bodyPart={bodyPart}
            setBodyPart={setBodyPart} />
          : <ExerciseCard exercise={item} />
          }
    </Box>
    )
    )}
    </ScrollMenu>
  )
} 

export default HorizontalScrollbar
*/