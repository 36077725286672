import React from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import HeroBannerImage from '../assets/images/Logo.png'

const HeroBanner = () => {
  return (
    <Box
    sx={{
        mt: { lg: '0px', xs: '5px' },
        ml: { lg: '80px', sm: '30px'}
    }}
    position="relative"
    p="10px 20px"
    >
        <Typography color="#333333"
        fontWeight="100"
        fontSize="26px"
        textTransform='uppercase'
        >
            Fitness APP
        </Typography>
        
        <img src={HeroBannerImage} alt="banner" className="hero-banner-img" />
    </Box>
  )
}

export default HeroBanner